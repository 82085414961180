const routes = {
  static: [
    { path: '/', changefreq: 'monthly', priority: 1.00 },
    { path: '/about-us', changefreq: 'monthly', priority: 0.80 },
    { path: '/about-us/mission-and-vision', changefreq: 'monthly', priority: 0.80 },
    { path: '/contact-us/reviews', changefreq: 'monthly', priority: 0.80 },
    { path: '/products', changefreq: 'monthly', priority: 0.80 },
    { path: '/products/visitors-canada-insurance', changefreq: 'monthly', priority: 0.80 },
    { path: '/products/trips', changefreq: 'monthly', priority: 0.80 },
    { path: '/products/trips/travel', changefreq: 'monthly', priority: 0.80 },
    { path: '/products/trips/youth', changefreq: 'monthly', priority: 0.80 },
    { path: '/products/student-accident-plan', changefreq: 'monthly', priority: 0.80 },
    { path: '/products/international-student', changefreq: 'monthly', priority: 0.80 },
    { path: '/products/isp', changefreq: 'monthly', priority: 0.70 },
    { path: '/products/insurance-for-visitors-to-canada-with-super-visa', changefreq: 'monthly', priority: 0.70 },
    { path: '/products/visitors-canada-insurance/monthly', changefreq: 'monthly', priority: 0.70 },
    { path: '/products/faqs', changefreq: 'monthly', priority: 0.70 },
    { path: '/about-our-underwriter', changefreq: 'monthly', priority: 0.70 },
    { path: '/claims', changefreq: 'monthly', priority: 0.80 },
    { path: '/claims/forms', changefreq: 'monthly', priority: 0.80 },
    { path: '/partner', changefreq: 'monthly', priority: 0.80 },
    { path: '/partner/agency-form', changefreq: 'monthly', priority: 0.80 },
    //{ path: '/partner/experiorfinancialgroup', changefreq: 'monthly', priority: 0.70 },
    { path: '/partner/reasons-to-partner-with-travelance', changefreq: 'monthly', priority: 0.70 },
    { path: '/partner/form', changefreq: 'monthly', priority: 0.80 },
    { path: '/partner/form/thank-you', changefreq: 'monthly', priority: 0.60 },
    //{ path: '/partner/greatwayfinancial', changefreq: 'monthly', priority: 0.70 },
    //{ path: '/partner/wfg-information', changefreq: 'monthly', priority: 0.70 },
    //{ path: '/partner/wfg-information/thank-you', changefreq: 'monthly', priority: 0.60 },
    { path: '/complaint-resolution-process', changefreq: 'monthly', priority: 0.80 },
    { path: '/partner/online-training-sessions', changefreq: 'monthly', priority: 0.80 },
    { path: '/partner/client-referral-program', changefreq: 'monthly', priority: 0.80 },
    { path: '/contact-us', changefreq: 'monthly', priority: 0.80 },
    { path: '/partner/faq-agents', changefreq: 'monthly', priority: 0.60 },
    { path: '/partner/resources', changefreq: 'monthly', priority: 0.70 },
    { path: '/privacy-policy', changefreq: 'monthly', priority: 0.80 },
    { path: '/blog', changefreq: 'monthly', priority: 0.80 },
    { path: '/terms-conditions', changefreq: 'monthly', priority: 0.80 },
    { path: '/partner/referral-form', changefreq: 'monthly', priority: 0.80 },

    { path: '/fr', changefreq: 'monthly', priority: 0.80 },
    { path: '/a-propos-de-nous', changefreq: 'monthly', priority: 0.70 },
    { path: '/a-propos-de-nous/mission-et-vision', changefreq: 'monthly', priority: 0.70 },
    { path: '/produits', changefreq: 'monthly', priority: 0.70 },
    { path: '/produits/assurance-visiteurs-au-canada-frair-medicaux-durgence', changefreq: 'monthly', priority: 0.70 },
    { path: '/produits/l-assurance-voyage', changefreq: 'monthly', priority: 0.70 },
    { path: '/produits/l-assurance-voyage/voyage', changefreq: 'monthly', priority: 0.70 },
    { path: '/produits/l-assurance-voyage/jeunes', changefreq: 'monthly', priority: 0.70 },
    { path: '/produits/regime-dassurance-accidents-pour-etudiants', changefreq: 'monthly', priority: 0.70 },
    { path: '/produits/police-dassurance-voyage-pour-les-etudiants-etrangers', changefreq: 'monthly', priority: 0.70 },
    { path: '/au-sujet-de-notre-assureur', changefreq: 'monthly', priority: 0.51 },
    { path: '/produits/produits-faq', changefreq: 'monthly', priority: 0.51 },
    { path: '/reclamations', changefreq: 'monthly', priority: 0.70 },
    { path: '/reclamations/formulaires', changefreq: 'monthly', priority: 0.70 },
    { path: '/partenaires', changefreq: 'monthly', priority: 0.70 },
    { path: '/partenaires/sassocier-avec-travelance', changefreq: 'monthly', priority: 0.70 },
    { path: '/processus-de-resolution-des-plaintes', changefreq: 'monthly', priority: 0.70 },
    { path: '/contactez-nous', changefreq: 'monthly', priority: 0.70 },
    { path: '/plan-du-site', changefreq: 'monthly', priority: 0.70 },
    { path: '/modalites-et-conditions', changefreq: 'monthly', priority: 0.70 },
    { path: '/politique-de-confidentialite', changefreq: 'monthly', priority: 0.70 },
    { path: '/partenaires/sessions-de-formation-en-ligne', changefreq: 'monthly', priority: 0.70 },
  ],
  staticEn: [
    { path: '/', title: 'Home' },
    { path: '/about-our-underwriter', title: 'About Our Underwriter' },
    { path: '/about-us', title: 'About Us', childs:[{ path: '/about-us/mission-and-vision', title: 'Our Mission' }]},
    { path: '/blog', title: 'Blogs' },
    { path: '/claims', title: 'Claims', childs:[{ path: '/claims/forms', title: 'Claims Submission and Forms'}] },
    { path: '/complaint-resolution-process', title: 'Complaint Resolution Process' },
    { path: '/contact-us', title: 'Contact Us', childs:[{ path: '/contact-us/reviews', title: 'Reviews', childs:[] }]},
    { path: '/partner', title: 'Partner', childs:[
      { path: '/partner/agency-form', title: 'Partner with Travelance', childs:[] },
      //{ path: '/partner/greatwayfinancial', title: 'Greatway Financial Information Center'},
      { path: '/partner/client-referral-program', title: 'Client Referral Program'},
      //{ path: '/partner/experiorfinancialgroup', title: 'Experior Financial Group Information Centre' },
      { path: '/partner/form', title: 'Partner with Travelance', childs:[{ path: '/partner/form/thank-you', title: 'Thank You'}] },
      { path: '/partner/faq-agents', title: 'FAQ Agents'},
      { path: '/partner/online-training-sessions', title: 'Online Training Sessions'},
      { path: '/partner/referral-form', title: 'Client Referral Form' },
      { path: '/partner/reasons-to-partner-with-travelance', title: '9 Reasons to Partner with Travelance'},
      { path: '/partner/resources', title: 'Resources'},
      //{ path: '/partner/wfg-information', title: 'WFG Partner Information Centre', childs:[{ path: '/partner/wfg-information/thank-you', title: 'Thank You' }] }
    ]},
    { path: '/products', title: 'Products', childs:[
      { path: '/products/faqs', title: 'FAQ'},
      { path: '/products/insurance-for-visitors-to-canada-with-super-visa', title: 'Super Visa Insurance Canada – Medical Insurance for Super Visa'},
      { path: '/products/international-student', title: 'International Student Plan'},
      { path: '/products/isp', title: 'Studying in Canada? Don’t Forget Insurance'},
      { path: '/products/student-accident-plan', title: 'Student Accident Plan (SAP)'},
      { path: '/products/trips', title: 'Travel Right Insurance Plans (TRIPS)', childs:[
        { path: '/products/trips/travel', title: 'Travel Insurance Products' },
        { path: '/products/trips/youth', title: 'Youth Travel Insurance' },
      ]},
      { path: '/products/visitors-canada-insurance', title: 'Visitors to Canada Emergency Medical Insurance (VTC)', childs:[{ path: '/products/visitors-canada-insurance/monthly', title: 'Super Visa Insurance Monthly Payment Plan'}]}
    ]},
    { path: '/privacy-policy', title: 'Privacy Policy'},
    { path: '/terms-conditions', title:'Terms and conditions' },
  ],
  staticFr: [
    { path: '/fr', title: 'Accueil' },
    { path: '/a-propos-de-nous', title: 'À propos de nous', childs: [{ path: '/a-propos-de-nous/mission-et-vision', title: 'Mission et vision' }] },
    { path: '/produits', title: 'Produits', childs: [
      { path: '/produits/assurance-visiteurs-au-canada-frair-medicaux-durgence', title: 'Visiteurs au Canada Assurance médicale d’urgence'},
      { path: '/produits/l-assurance-voyage', title: 'L’assurance voyage', childs: [ { path: '/produits/l-assurance-voyage/voyage', title: 'Voyage'},{ path: '/produits/l-assurance-voyage/jeunes', title: 'Jeunesse'},] },
      { path: '/produits/regime-dassurance-accidents-pour-etudiants', title: 'Assurance Accident étudiant'},
      { path: '/produits/police-dassurance-voyage-pour-les-etudiants-etrangers', title: 'Police d’assurance voyage pour les étudiants étrangers'},
      { path: '/produits/produits-faq', title: 'FAQs' },
    ] },
    { path: '/au-sujet-de-notre-assureur', title: 'Au sujet de notre assureur' },
    { path: '/partenaires', title: 'Partenaires', childs: [{ path: '/partenaires/sassocier-avec-travelance', title: 'S’associer avec Travelance'},{ path: '/partenaires/sessions-de-formation-en-ligne', title: 'Sessions de formation en ligne' },] },
    { path: '/reclamations', title: 'Réclamations', childs: [{ path: '/reclamations/formulaires', title: 'Formulaires'},] },
    { path: '/contactez-nous', title: 'Contactez-nous' },
    { path: '/processus-de-resolution-des-plaintes', title: 'Processus de résolution des plaintes' },
    { path: '/modalites-et-conditions', title: 'Modalités et conditions'},
    { path: '/politique-de-confidentialite', title: 'Politique de confidentialité'},
  ],

  dynamic: async () => {
    // Fetch dynamic routes, e.g., from an API or database
    const blogPosts = await fetch('https://www.travelance.ca/backend/wp-json/api/v1/tr/posts?per_page=All').then((res) => res.json());
    return blogPosts.posts_data.map((post) => ({
      path: `/blog/${post.name}`,
      changefreq: 'weekly',
      priority: calculatePriority(post.date),
    }));
  },
};
const calculatePriority = (date)=>{
  const today = new Date();
  const inputDate = new Date(date);

  // Calculate the difference in years
  const yearDifference = today.getFullYear() - inputDate.getFullYear();
  const monthDifference = today.getMonth() - inputDate.getMonth();
  const dayDifference = today.getDate() - inputDate.getDate();

  // Adjust for months and days
  const totalYearsDifference =
    yearDifference + monthDifference / 12 + dayDifference / 365;

  // Assign priority based on the range
  if (totalYearsDifference <= 1) return 0.65;
  if (totalYearsDifference <= 2) return 0.6;
  if (totalYearsDifference <= 3) return 0.55;
  if (totalYearsDifference <= 4) return 0.5;
  return 0.45; // Default priority for older dates
};

module.exports = routes;
